import React from 'react'; 
import { useState, useEffect, useRef } from 'react';
import './ProductVideo.css';
import VideoBackground from '../VideoBackground/VideoBackgound';
import ImageBackground from '../ImageBackground/ImageBackground';
import logo from '../../assets/Arc_logo_white.png';


function ProductVideo( {video, image, isMobile}) {
    const [hasEnded, setHasEnded] = useState(false);
    const videoContainerRef = useRef();
    const imageContainerRef = useRef();
    const textContainerRef = useRef();

    const handleClick = () => {
        window.location.href = 'https://5d9bb9.myshopify.com/products/arc-one';
      };


    useEffect(() => {
        if (hasEnded) {
            console.log('Video has ended!!');
            //Add class to videoref
            imageContainerRef.current.classList.add('showImage');
            videoContainerRef.current.classList.add('hideVideo');
            //add a delay
            setTimeout(() => {
                textContainerRef.current.classList.add('showText');
            }, 100);
        }
      }
    , [hasEnded]);

    useEffect(() => {
        if (isMobile) {
            imageContainerRef.current.classList.add('showImage');
            videoContainerRef.current.classList.add('hideVideo');
            //add a delay
            setTimeout(() => {
                textContainerRef.current.classList.add('showText');
            }, 100);
        }
    }, [isMobile]);

    return (
        <div className="ProductVideoBody">
            <div className='ArcLogoContainer'>
                <img className='ArcLogo' src={logo} alt="Arc Logo"/>
            </div>
            <div className="VideoBackgoundContainer" ref={videoContainerRef}>
                <VideoBackground src={video} setHasEnded={setHasEnded}/>
            </div>
            <div className="ImageBackgoundContainer" ref={imageContainerRef}>
                <ImageBackground src={image}/>
            </div>
            <div className="ProductVideoTextContainer" ref={textContainerRef}>
                <p className="ProductVideoTextTitle"><span className='' style={{fontWeight: 400}}>Arc One</span> is here</p>    
                <p className="ProductVideoTextSlogan">Your personal AI assistant</p>
                <div className="ProductVideoTextButton" onClick={handleClick}>Pre-order now </div>
            </div>
        </div>
      
    );
  }
  
  export default ProductVideo;