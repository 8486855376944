import React, { useRef, useEffect } from 'react';
import './VideoBackground.css';

const VideoBackground = ({ src, setHasEnded }) => {
  const videoRef = useRef();

  useEffect(() => {
    // When the component mounts, play the video
    videoRef.current.play();

    // Optional: Add event listener for when the video ends
    const handleVideoEnd = () => {
        setHasEnded(true)
      // You can add additional logic here if needed
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener('ended', handleVideoEnd);

    // Clean up
    return () => {
      videoElement.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

  return (
    <div className="video-background">
      <video ref={videoRef} src={src} muted autoPlay playsInline>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoBackground;
