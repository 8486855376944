import React from 'react'; 
import { useState, useEffect, useRef } from 'react';
import './ArcProductInformation.css';

import image from '../../assets/ArcFrontOutlineV1.png';


function ArcProductInformation() {

    const animation1TriggerRef = useRef(null);
    const imageRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            // Loop over the entries
            entries.forEach((entry) => {
              // If the element is visible
              if (entry.isIntersecting) {
                console.log('Element is visible');
                imageRef.current.classList.remove('ArcProductInformationContentImageTransformed');
                setTimeout(() => {
                    textRef.current.classList.remove('ArcProductInformationContentTextHidden');
                }, 500);
                // You can perform your actions here
              }
            });
          },
          {});
      
        // Now, let's observe the element
        if (animation1TriggerRef.current) {
            setTimeout(() => {
                observer.observe(animation1TriggerRef.current);
            }, 100);
        }
      
        // Cleanup function to unobserve the element
        return () => {
          if (animation1TriggerRef.current) {
            observer.unobserve(animation1TriggerRef.current);
          }
        };
      }, []); 

    return (
      <div className="ArcProductInformationBody">
        <div className="ArcProductInformationContainer">
            <div className='ArcProductInformationTitleContainer'>
                <div className='ArcProductInformationTitle'>Arc One</div>
                <div className='ArcProductInformationTitleUnderscore'></div>
            </div>
            <div className='ArcProductInformationContentContainer'>
                <div className='ArcProductInformationContentTextContainer ArcProductInformationContentTextHidden' ref={textRef}>
                    <p className="ArcProductInformationContentText">Arc One is designed to seamlessly integrate into your daily life, offering a sophisticated blend of professional assistance and friendly interaction. It's all about focusing on what truly matters: helping you manage your daily tasks effortlessly.</p>
                    <p className="ArcProductInformationContentText">Crafted with elegance, this state-of-the-art assistant embodies the perfect synergy of form and function. It’s your go-to for an extensive range of tasks from organizing your schedule and setting reminders to delivering instant answers and real-time updates. It's the perfect partner for anyone from the bustling professional seeking to optimize their workflow to the technology enthusiast looking to make everyday tasks more manageable.</p>
                </div>
                <div className='ArcProductInformationContentImageContainer'>
                    <img className='ArcProductInformationContentImage ArcProductInformationContentImageTransformed' src={image} alt="Arc One" ref={imageRef}/>
                </div>
            </div>
            <div ref={animation1TriggerRef}></div>
        </div>
        
      </div>
    );
  }
  
  export default ArcProductInformation;