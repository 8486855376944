import React from 'react';
import { useState, useEffect, useRef } from 'react';
import './ArcExamples.css';
import TypingTextSimulation from '../TypingTextSimulation/TypingTextSimulation';


function ArcExamples() {

    const animation1TriggerRef = useRef(null);

    const scrollDot1Ref = useRef(null);
    const scrollDot2Ref = useRef(null);
    const scrollDot3Ref = useRef(null);

    const [runAnimation1, setRunAnimation1] = useState(false);

    const [runAnimation1Text1, setRunAnimation1Text1] = useState(false);
    const [runAnimation1Text2, setRunAnimation1Text2] = useState(false);
    const [runAnimation1Text3, setRunAnimation1Text3] = useState(false);
    const [runAnimation1Text4, setRunAnimation1Text4] = useState(false);

    const [animation1Text1Complete, setAnimation1Text1Complete] = useState(false);
    const [animation1Text2Complete, setAnimation1Text2Complete] = useState(false);
    const [animation1Text3Complete, setAnimation1Text3Complete] = useState(false);
    const [animation1Text4Complete, setAnimation1Text4Complete] = useState(false);

    const ani1Text1Ref = useRef(null);
    const ani1Text2Ref = useRef(null);
    const ani1Text3Ref = useRef(null);
    const ani1Text4Ref = useRef(null);


    const [runAnimation2, setRunAnimation2] = useState(false);
    
    const [runAnimation2Text1, setRunAnimation2Text1] = useState(false);
    const [runAnimation2Text2, setRunAnimation2Text2] = useState(false);
    const [runAnimation2Text3, setRunAnimation2Text3] = useState(false);
    const [runAnimation2Text4, setRunAnimation2Text4] = useState(false);

    const [animation2Text1Complete, setAnimation2Text1Complete] = useState(false);
    const [animation2Text2Complete, setAnimation2Text2Complete] = useState(false);
    const [animation2Text3Complete, setAnimation2Text3Complete] = useState(false);
    const [animation2Text4Complete, setAnimation2Text4Complete] = useState(false);

    const ani2Text1Ref = useRef(null);
    const ani2Text2Ref = useRef(null);
    const ani2Text3Ref = useRef(null);
    const ani2Text4Ref = useRef(null);

    const [runAnimation3, setRunAnimation3] = useState(false);
    
    const [runAnimation3Text1, setRunAnimation3Text1] = useState(false);
    const [runAnimation3Text2, setRunAnimation3Text2] = useState(false);

    const [animation3Text1Complete, setAnimation3Text1Complete] = useState(false);
    const [animation3Text2Complete, setAnimation3Text2Complete] = useState(false);

    const ani3Text1Ref = useRef(null);
    const ani3Text2Ref = useRef(null);

    const [currentImage, setCurrentImage] = useState('conversation1');
    const conversation1Ref = useRef(null);
    const conversation2Ref = useRef(null);
    const conversation3Ref = useRef(null);
  
    const navigate = (direction) => {
        const sequence = ['conversation1', 'conversation2', 'conversation3'];
        console.log(currentImage);
        const currentIndex = sequence.indexOf(currentImage);
        let newIndex;
    
        if (direction === 'left') {
            newIndex = (currentIndex - 1 + sequence.length) % sequence.length;
        } else { // direction === 'right'
            newIndex = (currentIndex + 1) % sequence.length;
        }

        if (newIndex == 1) {
            setTimeout(() => {
                setRunAnimation2(true);
            }
            , 500);
        } else if (newIndex == 2) {
            setTimeout(() => {
                setRunAnimation3(true);
            }
            , 500);
        }
  
        setCurrentImage(sequence[newIndex]);
    };


    useEffect(() => {
        if (runAnimation1) {
            if (!runAnimation1Text1){
                setRunAnimation1Text1(true);
                ani1Text1Ref.current.classList.add('ArcExamplesShowUnderscore');
            } else if (!runAnimation1Text2) {
                setTimeout(() => {
                    setRunAnimation1Text2(true);
                    ani1Text2Ref.current.classList.add('ArcExamplesShowUnderscore');
                }, 600);
            } else if (!runAnimation1Text3) {
                setTimeout(() => {
                    setRunAnimation1Text3(true);
                    ani1Text3Ref.current.classList.add('ArcExamplesShowUnderscore');
                }, 700);
            } else if (!runAnimation1Text4) {
                setTimeout(() => {
                    setRunAnimation1Text4(true);
                    ani1Text4Ref.current.classList.add('ArcExamplesShowUnderscore');
                }, 500);
            }
        }
    }, [runAnimation1, animation1Text1Complete, animation1Text2Complete, animation1Text3Complete]);


    useEffect(() => {
        if (runAnimation2) {
            if (!runAnimation2Text1){
                setRunAnimation2Text1(true);
                ani2Text1Ref.current.classList.add('ArcExamplesShowUnderscore');
            } else if (!runAnimation2Text2) {
                setTimeout(() => {
                    setRunAnimation2Text2(true);
                    ani2Text2Ref.current.classList.add('ArcExamplesShowUnderscore');
                }, 600);
            } else if (!runAnimation2Text3) {
                setTimeout(() => {
                    setRunAnimation2Text3(true);
                    ani2Text3Ref.current.classList.add('ArcExamplesShowUnderscore');
                }, 700);
            } else if (!runAnimation2Text4) {
                setTimeout(() => {
                    setRunAnimation2Text4(true);
                    ani2Text4Ref.current.classList.add('ArcExamplesShowUnderscore');
                }, 500);
            }
        }
    }, [runAnimation2, animation2Text1Complete, animation2Text2Complete, animation2Text3Complete]);


    useEffect(() => {
        if (runAnimation3) {
            if (!runAnimation3Text1){
                setRunAnimation3Text1(true);
                ani3Text1Ref.current.classList.add('ArcExamplesShowUnderscore');
            } else if (!runAnimation3Text2) {
                setTimeout(() => {
                    setRunAnimation3Text2(true);
                    ani3Text2Ref.current.classList.add('ArcExamplesShowUnderscore');
                }, 600);
            }
        }
    }, [runAnimation3, animation3Text1Complete]);

  
    useEffect(() => {
        const allRefs = [conversation1Ref, conversation2Ref, conversation3Ref];
        const allScrollDots = [scrollDot1Ref, scrollDot2Ref, scrollDot3Ref];

        allRefs.forEach(ref => {
        var currendIndex = allRefs.indexOf(ref);
        if (ref.current.id === currentImage) {
            allScrollDots[currendIndex].current.classList.add('ArcExamplesScrollDotSelected');
            ref.current.classList.add('show');
            ref.current.classList.remove('hide');
        } else {
            allScrollDots[currendIndex].current.classList.remove('ArcExamplesScrollDotSelected');
            ref.current.classList.add('hide');
            ref.current.classList.remove('show');
        }
  
        if (ref.current.previousElementSibling?.id === currentImage || (currentImage === 'conversation1' && ref.current.id === 'conversation3')) {
            ref.current.classList.add('LeftPosition');
            ref.current.classList.remove('RightPosition');
        } else if (ref.current.nextElementSibling?.id === currentImage || (currentImage === 'conversation3' && ref.current.id === 'conversation1')) {
            ref.current.classList.add('RightPosition');
            ref.current.classList.remove('LeftPosition');
        } else {
            ref.current.classList.remove('LeftPosition');
            ref.current.classList.remove('RightPosition');
        }
      });
    }, [currentImage]);




    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            // Loop over the entries
            entries.forEach((entry) => {
              // If the element is visible
              if (entry.isIntersecting) {
                setRunAnimation1(true)
                // You can perform your actions here
              }
            });
          },
          {});
      
        // Now, let's observe the element
        if (animation1TriggerRef.current) {
          observer.observe(animation1TriggerRef.current);
        }
      
        // Cleanup function to unobserve the element
        return () => {
          if (animation1TriggerRef.current) {
            observer.unobserve(animation1TriggerRef.current);
          }
        };
      }, []); 



    return (
      <div className="ArcExamplesBody">

        <div className='ArcExamplesArrowContainer'>
            <div className='ArcExamplesArrowLeft' onClick={() => navigate('left')}>{"<"}</div>
            <div className='ArcExamplesArrowRight' onClick={() => navigate('right')}>{">"}</div>
        </div>

        <div className='ArcExamplesScrollDotsContainer'>
            <div className='ArcExamplesScrollDot' ref={scrollDot1Ref}></div>
            <div className='ArcExamplesScrollDot' ref={scrollDot2Ref}></div>
            <div className='ArcExamplesScrollDot' ref={scrollDot3Ref}></div>
        </div>


        <div className='ArcExamplesConversationBody' ref={conversation1Ref} id='conversation1'>
            <div className='ArcExamplesConversationTitleContainer'>
                <div className='ArcExamplesConversationTitle'>When you need some creativity</div>
                <div className='ArcExamplesConversationTitleUnderscore'></div>
            </div>
            <div className='ArcExamplesConversationContainer'>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesnMessagePerson'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="Help me come upp with a lunch recepie, im in a bit of a hurry." runAnimation={runAnimation1Text1} setAnimationComplete={setAnimation1Text1Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani1Text1Ref}></div>
                        </div>
                    </div>
                </div>
                <div className='ArcExamplesMessageContainer' ref={animation1TriggerRef}>
                    <div className='ArcExamplesMessageAI'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="How about a quick and healthy turkey and avocado wrap? Just spread some cream cheese on a whole wheat tortilla, add slices of turkey and avocado, sprinkle with salt and pepper, roll it up, and you're good to go. It's nutritious, delicious, and takes less than 5 minutes to make!" runAnimation={runAnimation1Text2} setAnimationComplete={setAnimation1Text2Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani1Text2Ref}></div>
                        </div>
                    </div>
                </div>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesnMessagePerson'>
                    <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="I’m out of tortillas." runAnimation={runAnimation1Text3} setAnimationComplete={setAnimation1Text3Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani1Text3Ref}></div>
                        </div>
                    </div>
                </div>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesMessageAI'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="No problem, replace the tortillas with bread and make a turkey and avocado sanwtich instead." runAnimation={runAnimation1Text4} setAnimationComplete={setAnimation1Text4Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani1Text4Ref}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='ArcExamplesConversationBody' ref={conversation2Ref} id='conversation2'>
            <div className='ArcExamplesConversationTitleContainer'>
                <div className='ArcExamplesConversationTitle'>When you need a reminder</div>
                <div className='ArcExamplesConversationTitleUnderscore'></div>
            </div>
            <div className='ArcExamplesConversationContainer'>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesnMessagePerson'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="Remind me to water the plants every Wednesday and Saturday." runAnimation={runAnimation2Text1} setAnimationComplete={setAnimation2Text1Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani2Text1Ref}></div>
                        </div>
                    </div>
                </div>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesMessageAI'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="Got it, I've set a reminder for you to water the plants every Wednesday and Saturday. Would you like me to remind you at a specific time on those days?" runAnimation={runAnimation2Text2} setAnimationComplete={setAnimation2Text2Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani2Text2Ref}></div>
                        </div>
                    </div>
                </div>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesnMessagePerson'>
                    <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="Remind me in the morning." runAnimation={runAnimation2Text3} setAnimationComplete={setAnimation2Text3Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani2Text3Ref}></div>
                        </div>
                    </div>
                </div>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesMessageAI'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="All right, I'll remind you to water the plants every Wednesday and Saturday morning." runAnimation={runAnimation2Text4} setAnimationComplete={setAnimation2Text4Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani2Text4Ref}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='ArcExamplesConversationBody' ref={conversation3Ref} id='conversation3'>
            <div className='ArcExamplesConversationTitleContainer'>
                <div className='ArcExamplesConversationTitle'>When you need a weather update</div>
                <div className='ArcExamplesConversationTitleUnderscore'></div>
            </div>
            <div className='ArcExamplesConversationContainer'>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesnMessagePerson'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="Im going to New York over the weekend, do i need to bring a raincoat." runAnimation={runAnimation3Text1} setAnimationComplete={setAnimation3Text1Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani3Text1Ref}></div>
                        </div>
                    </div>
                </div>
                <div className='ArcExamplesMessageContainer'>
                    <div className='ArcExamplesMessageAI'>
                        <div className='ArcExamplesnTextContainer'>
                            <TypingTextSimulation text="The weather forecast for New York this weekend indicates it won't rain, but it will be quite windy. You might not need a raincoat, but bringing a windbreaker or a sturdy jacket would be a good idea to stay comfortable in the windy conditions." runAnimation={runAnimation3Text2} setAnimationComplete={setAnimation3Text2Complete}/>
                            <div className='ArcExamplesnMessageUnderscore' ref={ani3Text2Ref}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



      </div>
    );
  }
  
  export default ArcExamples;