import React from 'react';
import './ImageBackground.css';

const ImageBackground = ({ src }) => {
  return (
    <div className="image-background" style={{ backgroundImage: `url(${src})` }} />
  );
};

export default ImageBackground;
