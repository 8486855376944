import React, { useState, useEffect } from 'react';

import './StartPage.css';
import ProductVideo from '../../components/ProductVideo/ProductVideo';
import ArcExamples from '../../components/ArcExamples/ArcExamples';
import ArcDynamicShowcase from '../../components/ArcDynamicShowcase/ArcDynamicShowcase';
import ArcProductInformation from '../../components/ArcProductInformation/ArcProductInformation';
import ArcFooter from '../../components/ArcFooter/ArcFooter';

import video from '../../assets/cycel_Arc_1s.mp4';
import image from '../../assets/cycel_Arc.png';

function StartPage() {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);

  useEffect(() => {
    // Create new instances for checking load status
    const img = new Image();
    img.src = image;
    img.onload = () => setImageLoaded(true);
  
    const vid = document.createElement('video');
    vid.src = video;
    vid.onloadeddata = () => setVideoLoaded(true)
  }, []);
  

  if (!videoLoaded || !imageLoaded) {
    //const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile & !loadingToggle) {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
      setLoadingToggle(true);
    }

    if (!isMobile) {
      return <div className='StartpageLoadingContainer'>{imageLoaded}</div>; // Or any other loading indicator
    }
    
  }

  return (
    <div className="App">
      <ProductVideo video={video} image={image} isMobile={isMobile}/>
      <ArcExamples/>
      <ArcDynamicShowcase/>
      <ArcProductInformation/>
      <ArcFooter/>
    </div>
  );
}
  
  export default StartPage;