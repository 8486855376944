import React from 'react';
import { useState, useEffect } from 'react';
import StartPage from './pages/StartPage/StartPage';
import './App.css';
import './fonts/IBM_Plex_Mono.css';


function App() {
  useEffect(() => {
    // Scroll to the top of the page on initial load
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, []);

  return (
    <div className="App">
        <StartPage/>
    </div>
  );
}

export default App;

