import React from 'react'; 
import './ArcDynamicShowcase.css';
import ImageBackground from '../ImageBackground/ImageBackground';

import image from '../../assets/Arc_one_3x.jpg';


function ArcDynamicShowcase() {

  const handleClick = () => {
    window.location.href = 'https://5d9bb9.myshopify.com/products/arc-one';
  };

    return (
      <div className="ArcDynamicShowcaseBody">
        <div className="ArcDynamicShowcaseImageBackground">
            <ImageBackground src={image}/>
        </div>
        <div className="ArcDynamicShowcaseTextContainer">
            <p className="ArcDynamicShowcaseTextTitle">Shipping summer 2024</p>
            <div className="ProductVideoTextButton" onClick={handleClick}>Pre-order now </div>
        </div>
      </div>
    );
  }
  
  export default ArcDynamicShowcase;