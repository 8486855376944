import React from 'react'; 
import { useState, useEffect } from 'react';
import './TypingTextSimulation.css';


function TypingTextSimulation({text, runAnimation, setAnimationComplete}) {

    const [textToDisplay, setTextToDisplay] = useState("");
    const [trigger, setTrigger] = useState(false); // New state variable to trigger the effect
    const [textLeftToDisplay, setTextLeftToDisplay] = useState(text.split(" "));


    useEffect(() => {
        if (runAnimation) {
            console.log("Animation started");
            setTextToDisplay(textLeftToDisplay[0] + " ");
            textLeftToDisplay.shift();
        }
    }, [runAnimation]);

    useEffect(() => {
        console.log("Text to display changed");
        if (runAnimation) {
            if (textLeftToDisplay.length > 0) {
                var wordLength = textLeftToDisplay[0].length;
                var randomMultiplier = Math.random() + 0.5;
                var timeToWait = wordLength * 18 * randomMultiplier;

                const timer = setTimeout(() => {
                    setTextToDisplay(textToDisplay + textLeftToDisplay[0] + " ");
                    setTextLeftToDisplay(textLeftToDisplay.slice(1));
                    setTrigger(!trigger); // Toggle the trigger to re-run the effect
                }, timeToWait);

                return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
            } else {
                setAnimationComplete(true);
            }
        }
    }, [textToDisplay, trigger]); // Depend on the trigger as well




    return (
      <div className="TypingTextSimulationsBody">
            <p className="TypingTextSimulationsText">{textToDisplay}</p>
      </div>
    );
  }
  
  export default TypingTextSimulation;