import React from 'react'; 
import './ArcFooter.css';

function ArcFooter() {
    return (
      <div className="ArcFooterBody">
        <p className="ArcFooterText">© 2024 Arc. All rights reserved.</p>
      </div>
    );
  }
  
  export default ArcFooter;